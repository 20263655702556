import { useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate"

import ErrorToast from './ErrorToast';


const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_NUMBER_REGEX = /^([+\d].*)?\d$/
const USER_URL = '/auth/user/';

const UserProfile = () => {
    const { t } = useTranslation();

    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const emailRef = useRef();
    // const errRef = useRef();

    const [email, setEmail] = useState(auth?.user?.email);
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState(auth?.user?.phone_number);
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

    const [address, setAddress] = useState(auth?.user?.address);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPhoneNumber(PHONE_NUMBER_REGEX.test(phoneNumber));
    }, [phoneNumber])

    useEffect(() => {
        setErrMsg('');
    }, [email])


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = EMAIL_REGEX.test(email);
        const v3 = PHONE_NUMBER_REGEX.test(phoneNumber);
        if (!v1 || !v3) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            await axiosPrivate.patch(USER_URL,
                JSON.stringify(
                    {
                        email,
                        phone_number: phoneNumber,
                        address
                    })
            );
            setSuccess(true);

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email Taken');
            } else {
                setErrMsg('Update Failed')
            }
            // errRef.current.focus();
        }
    }

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-xl-4">
                <div className="card-body text-center d-flex flex-column align-items-center">
                    {/* <p className={errMsg ? "errmsg alert alert-danger text-center" : "offscreen"} ref={errRef} aria-live="assertive">{errMsg}</p> */}
                    <ErrorToast errMsg={errMsg} />
                    <h2 className="fw-bold">{t("Profile")}</h2>
                    <p className="w-lg-50">{t("Update your profile information")}</p>
                    <br />
                    <form onSubmit={handleSubmit} data-bs-theme="light">
                        <input
                            type="text"
                            id="email"
                            ref={emailRef}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                            placeholder={t('Email')}
                            className='form-control mb-3'
                        />
                        {
                            emailFocus && email && !validEmail
                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                    {t('Invalid email address')}<br />
                                    {/* {t('Letters, numbers, underscores, hyphens allowed')} */}
                                </p>
                                : null
                        }

                        <input
                            type="text"
                            id="phone_number"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                            required
                            aria-invalid={validPhoneNumber ? "false" : "true"}
                            aria-describedby="phonenote"
                            onFocus={() => setPhoneNumberFocus(true)}
                            onBlur={() => setPhoneNumberFocus(false)}
                            autoComplete="off"
                            placeholder={t('Phone Number')}
                            className='form-control mb-3'
                        />
                        {
                            phoneNumberFocus && phoneNumber && !validPhoneNumber
                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                    {t("Invalid phone number")}<br />
                                </p>
                                : null
                        }

                        <input
                            type="text"
                            id="address"
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                            required
                            autoComplete="off"
                            placeholder={t('Address')}
                            className='form-control mb-3'
                        />
                        <button className="btn btn-primary shadow d-block w-100 mb-3" disabled={!validEmail || !validPhoneNumber || !address ? true : false}>{t("Update")}</button>
                    </form>
                    <Link to="/user/change-password">{t("Change password")}</Link>
                </div>
            </div>
        </div>
    )
}

export default UserProfile