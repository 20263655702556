import { StickerStar } from '@smastrom/react-rating'

const RATING_STYLES = {
    itemShapes: StickerStar,
    activeFillColor: '#ffb700',
    inactiveFillColor: '#fbf1a9'
}

const DEFAULT_ROLES = {
    owners: 'owners',
}

const BASE_URL = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000';

const TENANT_URL = '/v1/tenant/';
const SEARCH_URL = '/v1/search/';
const SHOPPING_CART_URL = '/v1/cart/';
const ORDER_URL = '/v1/order/';
const TAGS_URL = '/v1/tag/';

const BRAND_NAME = "Party Market";

const DEFAULT_LIMIT = 100;

export {
    DEFAULT_ROLES,
    BASE_URL,
    TENANT_URL,
    SEARCH_URL,
    SHOPPING_CART_URL,
    ORDER_URL, TAGS_URL,
    BRAND_NAME,
    RATING_STYLES,
    DEFAULT_LIMIT,
}