import { Link } from "react-router-dom";


const NavbarLogo = ({ brandName }) => {
    return (
        <div className="col-sm-3 mx-auto py-4">
            <div className="fs-4 fw-bold text-center">
                <Link to="/" style={{borderColor: "var(--bs-primary-text-emphasis)"}}>{brandName}</Link>
            </div>
        </div>
    )
}

export default NavbarLogo