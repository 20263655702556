import { useParams } from "react-router-dom";

import useAuth from "./useAuth";
import useStores from "./useStores";
import { getCurrentRoles } from "../utils/authUtils";


const useCurrentRolesLoader = () => {
    const { auth } = useAuth();
    const { store_slug } = useParams();
    const { stores } = useStores([]);

    const currentRolesLoader = () => {
        if (stores?.count > 0 && store_slug) {
            const roles = getCurrentRoles(auth, stores.results?.find(store => store.slug === store_slug))
            const result = roles?.length ? roles : []
            return result;        
        }

        return []
    }

    return currentRolesLoader 
}

export default useCurrentRolesLoader