import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import ReactGA from 'react-ga4';

import ContentHeader from './ContentHeader';
import AddToCartButton from "./AddToCartButton";
import Paginator from "./Paginator";

import useStores from "../hooks/useStores";
import useStoresLoader from "../hooks/useStoresLoader";
import { RATING_STYLES } from "../constants";

import './Stores.css';


const Stores = () => {
    const { t, i18n } = useTranslation();
    const { stores, } = useStores();
    const loadStores = useStoresLoader();

    ReactGA.send({ 
        hitType: 'pageview',
        page: '/stores',
        title: 'Stores'
    });

    const Product = ({ product }) => {
        const name = product['name_' + i18n.resolvedLanguage] || product.name_en;
        const description = product['description_' + i18n.resolvedLanguage] || product.description_en;
        return (
            <article className="card pe-2" style={{ width: "320px", minWidth: "320px" }}>
                <div className="card-body">
                    <div className="row">
                        <div className="col px-0 pb-2">
                            <img className="card-img card-img-top object-fit-cover shadow" alt={description} src={product.image} style={{ height: "300px" }} />
                        </div>
                    </div>
                    <div className="row px-0">
                        <div className="col px-0">
                            <h5 className="fw-bold">{name}</h5>
                        </div>
                        <div className="col col-auto px-0">
                            <p className="fw-bold text-end text-muted">{product.price} ILS</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col px-0">
                            <p className="text-muted">{description}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col px-0 pt-2">
                            <AddToCartButton product={product} />
                        </div>
                    </div>
                </div>
            </article>
        )
    }

    const ProductsList = ({ products }) => {
        if (products.length > 0) {
            return (
                products.map((product) => {
                    if (product.is_active === true) {
                        return <Product key={product.product_code} product={product} />
                    } else {
                        return <></>
                    }
                })
            )
        } else {
            return <></>
        }
    }

    const Store = ({ store }) => {
        return (
            <section className="row pb-5">
                <div className="col">
                    <Link to={"/" + store.slug} className="d-flex">
                        <h3 className="fw-bold w-100 mb-0">{store.name}</h3>
                        <Rating style={{ maxHeight: 32, maxWidth: 120 }} value={store.rating} itemStyles={RATING_STYLES} readOnly={true} />
                    </Link>
                    <hr />
                    <div className="tenant-products-scroll">
                        <div className="d-flex flex-row flex-nowrap">
                            <ProductsList products={store.products} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const StoresList = ({ stores }) => {
        if (stores.length > 0) {
            return (
                stores.map((store) => (<Store key={store.id} store={store} />))
            )
        } else {
            return (
                <p className="text-muted text-center">{t('No stores found')}</p>
            )
        }
    }

    return (
        <>
            <ContentHeader text={t('Stores')} subtext={t('Find your favorite store')} />
            <StoresList stores={stores.results} />
            <Paginator page_obj={stores} loader={loadStores}/>
        </>
    )
}

export default Stores