import { createContext, useState } from "react";


const SearchArgsContext = createContext({});

export const SearchArgsProvider = ({ children }) => {
    const [searchArgs, setSearchArgs] = useState({});

    return (
        <SearchArgsContext.Provider value={{ searchArgs, setSearchArgs }}>
            {children}
        </SearchArgsContext.Provider>
    )
}

export default SearchArgsContext;