import { createContext, useState } from "react";


const StoresContext = createContext({});

export const StoresProvider = ({ children }) => {
    const [stores, setStores] = useState({});

    return (
        <StoresContext.Provider value={{ stores, setStores }}>
            {children}
        </StoresContext.Provider>
    )
}

export default StoresContext;