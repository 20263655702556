import React from 'react';
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { StoresProvider } from './context/StoresProvider';
import { ShoppingCartProvider } from './context/ShoppingCartProvider';
import { SearchArgsProvider } from './context/SearchArgsProvider';

import './i18n';
import * as Sentry from "@sentry/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.partymarket\.co\.il/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <BrowserRouter>
        <SearchArgsProvider>

          <AuthProvider>
            <StoresProvider>
              <ShoppingCartProvider>

                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>

              </ShoppingCartProvider>
            </StoresProvider>
          </AuthProvider>

        </SearchArgsProvider>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
