const ErrorToast = ({ errMsg }) => {
    return (
        <div className="toast-container position-fixed bottom-0 end-0 p-3">

            <div className={"toast align-items-center text-white bg-danger border-0" + (errMsg ? " show" : " hide")}role="alert"
                aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className="toast-body">
                        <strong>{errMsg}</strong>
                    </div>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    )
}

export default ErrorToast