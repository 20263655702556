import { useLocation, Navigate, Outlet } from "react-router-dom";

import useCurrentRolesLoader from '../hooks/useCurrentRolesLoader'; 

import { isAllowed, isProhibited  } from "../utils/authUtils";


const RequireRoles = ({ allowedRoles, prohibitedRoles }) => {
    const location = useLocation();
    const loadCurrentRoles = useCurrentRolesLoader();
    
    const currentRoles = loadCurrentRoles();

    const hasRequiredRoles = allowedRoles?.length ? isAllowed(currentRoles, allowedRoles) : true;
    const hasProhibitedRoles = prohibitedRoles?.length ? isProhibited(currentRoles, prohibitedRoles) : false;

    if (hasRequiredRoles && !hasProhibitedRoles) {
        return <Outlet />
    }

    return <Navigate to="/unauthorized" state={{ from: location }} replace />
}

export default RequireRoles;