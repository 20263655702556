const options = {
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'short',
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
};

const apiDateConverter = (apiDate) => new Date(apiDate).toLocaleString(undefined, options);

export default apiDateConverter