import { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import axios from '../api/axios';

import useAuth from '../hooks/useAuth';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';

import { getAuth } from "../utils/authUtils";

import ErrorToast from './ErrorToast';

const LOGIN_URL = '/auth/login/';


const Login = () => {
    const { t } = useTranslation();

    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onVerify = useCallback((token) => {
        setToken(token)
    }, []);

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const emailRef = useRef();
    // const errRef = useRef();

    const [email, resetEmail, emailAttribs] = useInput('email', '');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', true);

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setRefreshReCaptcha(r => !r);

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify(
                    {
                        email,
                        password
                        // token,
                    }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    withCredentials: true
                }
            );

            setAuth(await getAuth(response));

            resetEmail();
            setPassword('');
            navigate(from, { replace: true });

        } catch (err) {
            // console.error("Login: ", JSON.stringify(err));
            if (!err?.response) {
                setErrMsg('No Server Response');
                // } else if (err.response?.status === 400) {
                //     setErrMsg('Missing Email or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            // errRef.current.focus();
        }
    }

    return (
        <section>
            <div className="row d-flex justify-content-center">
                <div className="col-md-6 col-xl-4">
                    <div className="card">
                        <div className="card-body text-center d-flex flex-column align-items-center">
                            {/* <p className={errMsg ? "errmsg alert alert-danger text-center" : "offscreen"}  aria-live="assertive">{errMsg}</p> */}
                            <ErrorToast errMsg={errMsg} />
                            <h2 className="fw-bold">{t('Login')}</h2>
                            <p className="w-lg-50">{t('Sign in to your account')}</p>
                            <br />
                            <form id="loginform" onSubmit={handleSubmit} method="post" data-bs-theme="light">
                                <GoogleReCaptcha
                                    onVerify={onVerify}
                                    refreshReCaptcha={refreshReCaptcha}
                                />
                                <input
                                    type="text"
                                    id="email"
                                    ref={emailRef}
                                    autoComplete="off"
                                    {...emailAttribs}
                                    required
                                    placeholder={t('Email')}
                                    className='form-control mb-3'
                                />
                                <input
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    placeholder={t('Password')}
                                    className='form-control mb-3'
                                />
                                <button className="btn btn-primary shadow d-block w-100 mb-3">{t('Login')}</button>
                                <div className="persistCheck form-check mb-3 mx-5">
                                    <input
                                        type="checkbox"
                                        id="persist"
                                        onChange={toggleCheck}
                                        checked={check}
                                        className='form-check-input'
                                    />
                                    <label htmlFor="persist" className="text-muted form-check-label">{t('Trust This Device')}</label>
                                </div>
                            </form>
                            <p className="text-muted">
                                <span>{t("Don't have an account") + "? "}</span>
                                <Link to="/register">{t('Register')}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Login
