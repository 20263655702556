import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Tags from '@yaireo/tagify/dist/react.tagify.jsx' // React-wrapper file
import '@yaireo/tagify/dist/tagify.css' // Tagify CSS

import ContentHeader from './ContentHeader';
import ErrorToast from './ErrorToast';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useStores from "../hooks/useStores";
import { BASE_URL, TENANT_URL, TAGS_URL, DEFAULT_LIMIT } from "../constants";


const ProductDetails = () => {
    const { t } = useTranslation();
    const { store_slug, product_code } = useParams();
    const { stores } = useStores();

    const [store, setStore] = useState({});
    const [product, setProduct] = useState({});
    const [errMsg, setErrMsg] = useState('');

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const getStoreProduct = async () => {
            try {
                const currentStore = stores?.results?.find(store => store.slug === store_slug)
                setStore(currentStore);
                const response = await axiosPrivate.get(TENANT_URL + currentStore.id + '/product/', { params: { limit: DEFAULT_LIMIT } });
                if (product_code !== 'new') {
                    setProduct(response.data?.results?.find(product => product.product_code === product_code));
                }
            } catch (err) {
                console.error(err);
            }
        }
        getStoreProduct();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let form_data = new FormData();

            form_data.append('name_en', product.name_en ? product.name_en : '');
            form_data.append('name_he', product.name_he ? product.name_he : '');
            form_data.append('name_ru', product.name_ru ? product.name_ru : '');
            form_data.append('description_en', product.description_en ? product.description_en : '');
            form_data.append('description_he', product.description_he ? product.description_he : '');
            form_data.append('description_ru', product.description_ru ? product.description_ru : '');
            form_data.append('price', product.price ? product.price : 0.0);
            product.tags.forEach(tag => form_data.append('tags', tag));

            if (product.hasOwnProperty('is_active')) {
                form_data.append('is_active', product.is_active);
            } else {
                form_data.append('is_active', true);
            }

            if (e.target.elements.image.files.length > 0) {
                form_data.append('image', e.target.elements.image.files[0]);
            }

            if (!form_data.get('image') && !product.image) return alert("Enter a valid file");

            let response = undefined;
            if (product_code === 'new') {
                response = await axiosPrivate.post(TENANT_URL + store.id + '/product/',
                    form_data,
                    {
                        baseURL: BASE_URL,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        withCredentials: true
                    },
                )
                navigate(`/${store_slug}/product/edit/${response.data.product_code}`);
            }
            else {
                response = await axiosPrivate.patch(TENANT_URL + store.id + '/product/' + product.id + '/',
                    form_data,
                    {
                        baseURL: BASE_URL,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        withCredentials: true
                    },
                )
            }
            setProduct(response?.data);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else {
                let message = "Update Failed: ";
                for (const key in err?.response?.data) {
                    message += `${key}: ${err?.response?.data[key]} `;
                }
                setErrMsg(message);
            }
            // errRef.current.focus();
        }
    }

    const ProductTags = ({ product }) => {

        const onInput = useCallback((e) => {
            var tagify = e.detail.tagify
            const value = e.detail.value

            tagify.whitelist = null // reset the whitelist

            // show loading animation and hide the suggestions dropdown
            tagify.loading(true).dropdown.hide()

            axiosPrivate.get(TAGS_URL, { params: { limit: 10, name: value } }).then(({ data }) => {
                tagify.whitelist = data.results.map((tag) => tag.name); // update whitelist Array in-place
                tagify.loading(false).dropdown.show(value) // render the suggestions dropdown
            })
        }, [])

        const onChange = useCallback((e) => {
            setProduct({
                ...product,
                tags: e.detail.tagify.getCleanValue().map((tag) => tag.value)
            })
        }, [])

        return (
            <Tags
                placeholder={t("Tags")}
                settings={{
                    // maxTags: 4,
                    maxItems: 15,
                    dropdown: {
                        enabled: 1
                    }
                }}
                defaultValue={product.tags ? product.tags : []}
                onInput={onInput}
                onChange={onChange}
            // className="customLook"
            />
        )
    }

    const ProductImage = ({ product }) => {
        if (product.image || product_code !== 'new') {
            return (
                <a href={product.image} className="text-center">
                    <img className="card-img card-img-top object-fit-cover shadow" alt={product.description} src={product.image} style={{ height: "35vh" }} />
                </a>
            )
        } else {
            return <></>
        }
    }

    if (product) {
        return (
            <>
                <ContentHeader text={t('Product Details')} subtext={t('Create or edit a product')} />
                <div className="row">
                    <div className="col col-md-8 col-xl-6 mx-auto">
                        {/* <p className={errMsg ? "errmsg alert alert-danger text-center" : "offscreen"} ref={errRef} aria-live="assertive">{errMsg}</p> */}
                        <ErrorToast errMsg={errMsg} />
                        <form onSubmit={handleSubmit} data-bs-theme="light">

                            <label htmlFor="image" className="form-label">{t("Image")}</label>
                            <ProductImage product={product} />
                            <input
                                type='file'
                                id='image'
                                name='image'
                                accept="image/jpeg,image/png,image/gif"
                                className='form-control my-3'
                                required={!product.image}
                            // onChange={(e) => setProduct({ ...product, image: URL.createObjectURL(e.target.files[0]) })}
                            />

                            <br />
                            <label htmlFor="name_en" className="form-label">{t("Name") + " [en]"}</label>
                            <input
                                type='text'
                                id='name_en'
                                value={product.name_en ? product.name_en : ''}
                                placeholder={t("Name") + " [en]"}
                                className='form-control mb-3'
                                onChange={(e) => setProduct({ ...product, name_en: e.target.value })}
                                // onChange={(e) => setProductNameEn(e.target.value)}
                                required
                            />

                            <label htmlFor="name_he" className="form-label">{t("Name") + " [he]"}</label>
                            <input
                                type='text'
                                id='name_he'
                                value={product.name_he ? product.name_he : ''}
                                placeholder={t("Name") + " [he]"}
                                className='form-control mb-3'
                                onChange={(e) => setProduct({ ...product, name_he: e.target.value })}
                            />

                            <label htmlFor="name_ru" className="form-label">{t("Name") + " [ru]"}</label>
                            <input
                                type='text'
                                id='name_ru'
                                value={product.name_ru ? product.name_ru : ''}
                                placeholder={t("Name") + " [ru]"}
                                className='form-control mb-3'
                                onChange={(e) => setProduct({ ...product, name_ru: e.target.value })}
                            />

                            <label htmlFor="description_en" className="form-label">{t("Description") + " [en]"}</label>
                            <textarea
                                id='description_en'
                                value={product.description_en ? product.description_en : ''}
                                placeholder={t("Description") + " [en]"}
                                className='form-control mb-3'
                                rows={3}
                                onChange={(e) => setProduct({ ...product, description_en: e.target.value })}
                                required
                            />

                            <label htmlFor="description_he" className="form-label">{t("Description") + " [he]"}</label>
                            <textarea
                                id='description_he'
                                value={product.description_he ? product.description_he : ''}
                                placeholder={t("Description") + " [he]"}
                                className='form-control mb-3'
                                rows={3}
                                onChange={(e) => setProduct({ ...product, description_he: e.target.value })}
                            />

                            <label htmlFor="description_ru" className="form-label">{t("Description") + " [ru]"}</label>
                            <textarea
                                id='description_ru'
                                value={product.description_ru ? product.description_ru : ''}
                                placeholder={t("Description") + " [ru]"}
                                className='form-control mb-3'
                                rows={3}
                                onChange={(e) => setProduct({ ...product, description_ru: e.target.value })}
                            />

                            <label htmlFor="price" className="form-label">{t("Price")}</label>
                            <input
                                type='number'
                                id='price'
                                value={product.price ? product.price : 0.0}
                                placeholder={t("Price")}
                                className='form-control mb-3'
                                onChange={(e) => setProduct({ ...product, price: e.target.value })}
                                required
                            />

                            <label className="form-label">{t("Tags")}</label>
                            <br />
                            <ProductTags product={product} />

                            <div className="form-check mt-2">
                                <label htmlFor="is_active" className="form-check-label">{t("Is Active")}</label>
                                <input
                                    type="checkbox"
                                    id="is_active"
                                    checked={product.is_active}
                                    onChange={(e) => setProduct({ ...product, is_active: e.target.checked })}
                                    className="form-check-input"
                                    value={product.is_active}
                                />
                                <br />
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary shadow mt-4" type="submit">
                                    {t("Save")}
                                </button>
                            </div>
                        </form >
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <ContentHeader text={t('Loading') + "..."} subtext={t('Please wait')} />
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </>
        )
    }
}

export default ProductDetails