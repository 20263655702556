import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

import useAuth from "../hooks/useAuth";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useShoppingCartLoader from "../hooks/useShoppingCartLoader";
import useShoppingCart from "../hooks/useShoppingCart";

import { SHOPPING_CART_URL, BRAND_NAME, DEFAULT_LIMIT } from "../constants";

import './AddToCartButton.css';

const AddToCartButton = ({ product }) => {
    const { auth } = useAuth();
    const { t } = useTranslation();
    const { shoppingCart, setShoppingCart } = useShoppingCart();
    const [forceShowToast, setForceShowToast] = useState(0);
    
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const loadShoppingCart = useShoppingCartLoader();
    
    const addToCart = async () => {
        ReactGA.event({
            category: 'Shopping Cart',
            action: 'Add to cart',
            label: product.name + ' - ' + product.tenant
        });

        try {
            if (auth?.accessToken && auth?.user) {
                try {
                    await loadShoppingCart();
                } catch (err) {
                    console.error(err);
                }
            } else {
                navigate('/login');
            }

            let cart = shoppingCart;

            if (Object.keys(cart).length === 0) {
                const response = await axiosPrivate.post(SHOPPING_CART_URL, {
                    tenant: product.tenant
                });
                cart = response?.data;
                setShoppingCart(cart);
            }

            const response = await axiosPrivate.get(SHOPPING_CART_URL + cart.id + '/item/', { params: { limit: DEFAULT_LIMIT } });
            const cartProductsData = response?.data;
            if (cart.tenant !== product.tenant) {
                if (cartProductsData.count === 0) {
                    await axiosPrivate.delete(SHOPPING_CART_URL);
                    cart = await axiosPrivate.post(SHOPPING_CART_URL, {
                        tenant: product.tenant
                    })
                } else {
                    // console.error("Shopping cart and product do not belong to the same tenant");
                    setForceShowToast(forceShowToast + 1);
                    setTimeout(() => {
                        setForceShowToast(0);
                    }, 3000);
                }
            } else {
                const existingProduct = cartProductsData.results.find(cartProduct => cartProduct.product === product.id);
                if (existingProduct) {
                    await axiosPrivate.patch(SHOPPING_CART_URL + cart.id + '/item/' + existingProduct.id + '/',
                        {
                            quantity: existingProduct.quantity + 1
                        }
                    );
                } else {
                    await axiosPrivate.post(SHOPPING_CART_URL + cart.id + '/item/',
                        {
                            product: product.id,
                            quantity: 1
                        }
                    );
                }
                await loadShoppingCart();
            }
        } catch (err) {
            console.error(err);
        }
    }

    const removeShoppingCart = async ({ cart }) => {
        await axiosPrivate.delete(SHOPPING_CART_URL + cart.id + '/');
        setShoppingCart({});
    }
    
    const AddProductErrorToast = ({ store, show }) => {
        return (
            <div className="toast-container position-fixed bottom-0 end-0 p-3">
                <div id="liveToast" className={"toast" + (show > 0 ? " show" : "")} role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-header">
                        <strong className="me-auto">{ BRAND_NAME }</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div className="toast-body">
                        {t('You already have products from')} <strong>{store.name}</strong>.
                        <hr />
                        {t("Unfoturanetly, you can't add products from different stores to the same cart.")}
                        {t("Please complete your current order or empty your cart to add products from another store.")}
                        <hr />
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => removeShoppingCart({ cart: shoppingCart })}>{t("Empty your cart now")}</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <button className="btn btn-primary shadow add-to-cart-button" onClick={addToCart}>{t("Add to Cart")}</button>
            <AddProductErrorToast store={product.tenant} show={forceShowToast}/>
        </>

    )
}

export default AddToCartButton