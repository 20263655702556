import { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Rating } from '@smastrom/react-rating'
import ReactGA from 'react-ga4';
import '@smastrom/react-rating/style.css'


import useAuth from "../hooks/useAuth";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useStores from "../hooks/useStores";
import useSearchArgs from "../hooks/useSearchArgs";

import { isAllowed, getCurrentRoles } from "../utils/authUtils";
import { DEFAULT_ROLES, TENANT_URL, RATING_STYLES } from "../constants";

import ContentHeader from './ContentHeader';
import Loading from "./Loading";
import AddToCartButton from './AddToCartButton';
import Paginator from "./Paginator";

import './Store.css';


const Store = () => {
    const { auth } = useAuth();
    const { t, i18n } = useTranslation();
    const { store_slug } = useParams();
    const { stores, } = useStores([]);
    const { searchArgs } = useSearchArgs();

    const [store, setStore] = useState({});
    const [products, setProducts] = useState([]);
    const [currentRoles, setCurrentRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    const axiosPrivate = useAxiosPrivate();

    const currentStore = stores.results.find(store => store.slug === store_slug)

    ReactGA.send({ 
        hitType: 'pageview',
        page: '/store/' + store_slug,
        title: 'Store: ' + store_slug
    });

    const getProducts = async (params = { ...searchArgs }) => {
        try {
            const response = await axiosPrivate.get(TENANT_URL + currentStore.id + '/product/', { params });
            setProducts(response?.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setStore(currentStore);
        setCurrentRoles(getCurrentRoles(auth, currentStore));
        
        getProducts();
    }, [])

    const StoreHeader = ({ store }) => {
        return (
            <>
            <div className="row mb-4 mb-lg-5">
                <div className="col-2 text-center mx-auto">
                <Rating value={store.rating} itemStyles={RATING_STYLES} readOnly={true} />
                </div>
            </div>
            <div className="row mb-4 mb-lg-5">
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                    <p className="text-muted">{t('Dielivery in')}: <strong>{store.city}</strong>,
                        <strong>{store.delivery_time_max}</strong>, <strong>{store.delivery_price}</strong> ILS</p>
                </div>
            </div>
            </>
        )
    }

    const EditProductButton = ({ product }) => {
        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
            return (
                <Link to={`/${store.slug}/product/edit/${product.product_code}`}>
                    <span className="fs-6 position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger edit-item-button px-2">
                        <svg className="bi bi-pencil fs-6" xmlns="http://www.w3.org/2000/svg"
                            width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path>
                        </svg>
                    </span>
                </Link>
            )
        } else {
            return <></>
        }
    }

    const Product = ({ product }) => {
        const name = product['name_' + i18n.resolvedLanguage] || product.name_en;
        const description = product['description_' + i18n.resolvedLanguage] || product.description_en;
        return (
            <li className="col-md-4">
                <article className="card px-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col px-0 pb-2 position-relative">
                                <img className="card-img card-img-top object-fit-cover shadow" alt={description} src={product.image} style={{ height: "25vh" }} />
                                <EditProductButton product={product} />
                            </div>
                        </div>
                        <div className="row px-0">
                            <div className="col px-0">
                                <h5 className="fw-bold">{name}</h5>
                            </div>
                            <div className="col col-auto px-0">
                                <p className="fw-bold text-end text-muted">ILS {product.price}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col px-0">
                                <p className="text-muted">{description}</p>
                            </div>
                        </div>
                        {
                            isAllowed(currentRoles, [DEFAULT_ROLES.owners])
                                ? <></>
                                : <AddToCartButton product={product} />
                        }
                    </div>
                </article>
            </li>
        )
    }

    const ProductsList = ({ products }) => {
        if (products.length > 0) {
            return (
                products.map((product) => {
                    if (product.is_active === true || isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
                        return <Product key={product.product_code} product={product} />
                    } else {
                        return <></>
                    }
                })
            )
        } else {
            return <></>
        }
    }

    const AddNewProduct = () => {
        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
            return (
                <li className="col-md-4">
                    <div className="card px-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col px-0 pb-2" style={{ color: "var(--bs-success)" }}>
                                    <Link to={`/${store.slug}/product/edit/new`}>
                                        <div className="d-flex justify-content-center align-items-center"
                                            style={{ height: "25vh", borderRadius: "25px", borderStyle: "dashed", borderColor: "var(--bs-success)" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                fill="currentColor" viewBox="0 0 16 16"
                                                className="bi bi-plus-square-dotted fs-1" style={{ color: "var(--bs-success)" }}>
                                                <path d="M2.5 0c-.166 0-.33.016-.487.048l.194.98A1.51 1.51 0 0 1 2.5 1h.458V0H2.5zm2.292 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zm1.833 0h-.916v1h.916V0zm1.834 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zM13.5 0h-.458v1h.458c.1 0 .199.01.293.029l.194-.981A2.51 2.51 0 0 0 13.5 0zm2.079 1.11a2.511 2.511 0 0 0-.69-.689l-.556.831c.164.11.305.251.415.415l.83-.556zM1.11.421a2.511 2.511 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415L1.11.422zM16 2.5c0-.166-.016-.33-.048-.487l-.98.194c.018.094.028.192.028.293v.458h1V2.5zM.048 2.013A2.51 2.51 0 0 0 0 2.5v.458h1V2.5c0-.1.01-.199.029-.293l-.981-.194zM0 3.875v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 5.708v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 7.542v.916h1v-.916H0zm15 .916h1v-.916h-1v.916zM0 9.375v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .916v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .917v.458c0 .166.016.33.048.487l.98-.194A1.51 1.51 0 0 1 1 13.5v-.458H0zm16 .458v-.458h-1v.458c0 .1-.01.199-.029.293l.981.194c.032-.158.048-.32.048-.487zM.421 14.89c.183.272.417.506.69.689l.556-.831a1.51 1.51 0 0 1-.415-.415l-.83.556zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373c.158.032.32.048.487.048h.458v-1H2.5c-.1 0-.199-.01-.293-.029l-.194.981zM13.5 16c.166 0 .33-.016.487-.048l-.194-.98A1.51 1.51 0 0 1 13.5 15h-.458v1h.458zm-9.625 0h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zm1.834-1v1h.916v-1h-.916zm1.833 1h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path>
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="row px-0">
                                <div className="col px-0">
                                    <h5 className="fw-bold" style={{ textAlign: "center" }}>{t('Add new product')}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        }
    }

    const Result = ({ store, products, isLoading }) => {
        if (isLoading) {
            return (
                <Loading />
            )
        } else {
            return (
                <section>
                    <ContentHeader text={store.name} subtext={store.description} />
                    <StoreHeader store={store} />
                    <ul className="row list-unstyled">
                        <AddNewProduct />
                        <ProductsList products={products.results} />
                    </ul>
                    <Paginator page_obj={products} loader={getProducts}  />
                </section>
            )
        }
    }

    return <Result store={store} products={products} isLoading={isLoading} />
}

export default Store