import NavbarLogo from "./NavbarLogo";
import NavbarSearch from "./NavbarSearch";
import NavbarButtons from "./NavbarButtons";


const Navbar = ({ brandName }) => {
    return (
        // <nav className="navbar navbar-light sticky-top navbar-shrink" id="mainNav" style={{ background: "rgba(255,255,255,0.75)", backdropFilter: "blur(30px)" }}>
        <nav className="navbar navbar-light navbar-shrink" id="mainNav" style={{ background: "rgba(255,255,255,0.75)", backdropFilter: "blur(30px)" }}>
            <div className="container container-fluid">
                <NavbarLogo brandName={brandName} />
                <NavbarSearch />
                <NavbarButtons />
            </div>
        </nav>
    )
}

export default Navbar