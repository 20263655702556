import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';

import useShoppingCart from "../hooks/useShoppingCart";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useShoppingCartLoader from "../hooks/useShoppingCartLoader";
import { SHOPPING_CART_URL, ORDER_URL, TENANT_URL } from "../constants";


const ShoppingCart = () => {
    const { t } = useTranslation();
    const { shoppingCart, setShoppingCart } = useShoppingCart({});

    const [showCreateOrderButtonLoader, setCreateOrderButtonLoader] = useState(false)
    
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const loadShoppingCart = useShoppingCartLoader();


    const RemoveAllProductItemsFromShoppingCart = ({ cartItem }) => {
        const removeAllProductItemsFromShoppingCart = async () => {
            try {
                await axiosPrivate.delete(SHOPPING_CART_URL + cartItem.shopping_cart + '/item/' + cartItem.id + '/');
                // let shoppingCartItems = shoppingCart.shopping_cart_items;
                // shoppingCartItems = shoppingCartItems.filter(item => item.id !== cartItem.id);
                // setShoppingCart({
                //     ...shoppingCart,
                //     shopping_cart_items: shoppingCartItems
                // })
                await loadShoppingCart();
            } catch (err) {
                if (err.response.status === 404) {
                    setShoppingCart({});
                    navigate('/');
                } else {
                    console.error(err);
                }
            }
        }

        return (
            <a className="text-bg-danger bs-icon bs-icon-sm bs-icon-circle" href="#" type="button" onClick={removeAllProductItemsFromShoppingCart}>
                <svg className="bi bi-trash3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"></path>
                </svg>
            </a>
        )
    }

    const RemoveProductItemFromShoppingCart = ({ cartItem }) => {
        const removeProductFromShoppingCart = async () => {
            try {
                await axiosPrivate.patch(SHOPPING_CART_URL + cartItem.shopping_cart + '/item/' + cartItem.id + '/',
                    {
                        quantity: cartItem.quantity - 1
                    }
                );
                // let shoppingCartItems = shoppingCart.shopping_cart_items;
                // shoppingCartItems.find(item => item.id === cartItem.id).quantity -= 1;
                // setShoppingCart({
                //     ...shoppingCart,
                //     shopping_cart_items: shoppingCartItems
                // })
                await loadShoppingCart();
            } catch (err) {
                if (err.response.status !== 404) {
                    console.error(err);
                } else {
                    setShoppingCart({});
                    navigate('/');
                }
            }
        }

        return (
            <button className="btn btn-outline-primary btn-sm px-1" onClick={removeProductFromShoppingCart}>
                <span>-</span>
            </button>
        )
    }

    const AddSameProductItemToShoppingCart = ({ cartItem }) => {
        const addSameProductToShoppingCart = async () => {
            try {
                await axiosPrivate.patch(SHOPPING_CART_URL + cartItem.shopping_cart + '/item/' + cartItem.id + '/',
                    {
                        quantity: cartItem.quantity + 1
                    }
                );
                // let shoppingCartItems = shoppingCart.shopping_cart_items;
                // shoppingCartItems.find(item => item.id === cartItem.id).quantity += 1;
                // setShoppingCart({
                //     ...shoppingCart,
                //     shopping_cart_items: shoppingCartItems
                // })
                await loadShoppingCart();
            } catch (err) {
                console.error(err);
            }
        }

        return (
            <button className="btn btn-outline-primary btn-sm px-1" onClick={addSameProductToShoppingCart}>
                <span>+</span>
            </button>
        )
    }

    const ShoppingCartItem = ({ cartItem }) => {
        return (
            <tr>
                <td className="align-middle">
                    <img className="rounded card-img card-img-top object-fit-cover shadow"
                        src={cartItem.product.image}
                        alt={cartItem.product.name} style={{ width: "10vh", height: "10vh" }} />
                </td>
                <td className="align-middle">{cartItem.product.name}</td>
                <td className="align-middle">{cartItem.product.price} ILS</td>
                <td className="align-middle">
                    <div className="d-flex flex-row">
                        <div className="input-group align-self-center" name="search" style={{ width: "4em" }}>
                            <RemoveProductItemFromShoppingCart cartItem={cartItem} />
                            <input readOnly className="form-control form-control-sm p-1 text-center" type="text" value={cartItem.quantity} />
                            <AddSameProductItemToShoppingCart cartItem={cartItem} />
                        </div>
                        <div className="align-self-center mx-1">
                            <RemoveAllProductItemsFromShoppingCart cartItem={cartItem} />
                        </div>
                    </div>
                </td>
                <td className="text-end align-middle">{cartItem.subtotal} ILS</td>
            </tr>
        )
    }

    const ShoppingCartItems = ({ cart }) => {
        return (
            <tbody>
                {cart.shopping_cart_items.map(cartItem => <ShoppingCartItem key={cartItem.id} cartItem={cartItem} />)}
            </tbody>
        )
    }

    const ShoppingCartOrderButton = ({ cart, isLoading = false }) => {
        const createOrder = async ( cart ) => {
            try {
                setCreateOrderButtonLoader(true);
                const responseTeanant = await axiosPrivate.get(TENANT_URL + cart.tenant + '/');
                const orderStatus = responseTeanant?.data?.statuses.order.find(status => status.slug === 'new');

                const responseOrder = await axiosPrivate.post(ORDER_URL, {
                    "tenant": cart.tenant,
                    "order_status": orderStatus.id,
                })

                cart.shopping_cart_items.forEach(cartItem => {
                    axiosPrivate.post(ORDER_URL + responseOrder?.data?.id + '/item/', {
                        "product": cartItem.product.id,
                        "quantity": cartItem.quantity,
                        // "price": cartItem.product.price,
                    });
                })
                
                await axiosPrivate.delete(SHOPPING_CART_URL + cart.id + '/');
                setShoppingCart({});
    
                navigate('/user/orders/');

            } catch (err) {
                console.error(err);
            }
        }

        return (
            <button className="btn btn-primary text-end shadow mt-2 text-align-middle" onClick={() => createOrder(cart)}>
                {t("Place order")}
                <div className={"ms-2 spinner-border spinner-border-sm " + (isLoading ? "" : "visually-hidden")} role="status">
                    <span className="visually-hidden">Loading</span>
                </div>
            </button>
        )
    }

    const ShoppingCartTotal = ({ cart }) => {
        return (
            <div className="row">
                <div className="col text-end">
                    <h5 className="fw-bold">{t("Subtotal")}: {cart.subtotal} ILS</h5>
                    <ShoppingCartOrderButton cart={cart} isLoading={showCreateOrderButtonLoader} />
                </div>
            </div>
        )
    }

    const Result = ({ cart }) => {
        if (cart?.shopping_cart_items?.length > 0) {
            return (
                <>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t("Image")}</th>
                                    <th>{t("Product")}</th>
                                    <th>{t("Price")}</th>
                                    <th>{t("Quantity")}</th>
                                    <th className="text-end">{t("Subtotal")}</th>
                                </tr>
                            </thead>
                            <ShoppingCartItems cart={cart} />
                        </table>
                    </div>
                    <br />
                    <ShoppingCartTotal cart={cart} />
                </>
            )
        } else {
            return (
                <div className="row">
                    <div className="col text-center">
                        <h5 className="fw-bold">{t('Your cart is empty')}</h5>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <ContentHeader text={t('Shopping Cart')} subtext={t('Your shopping cart')} />
            <Result cart={shoppingCart} />
        </>
    )
}

export default ShoppingCart