const ContentHeader = ({ text, subtext }) => {
    return (
        <div className="row mb-4 mb-lg-5">
            <div className="col-md-8 col-xl-6 text-center mx-auto">
                <h2 className="fw-bold">{text}</h2>
                <p className="text-muted">{subtext}</p>
            </div>
        </div>
    );
}

export default ContentHeader