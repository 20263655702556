import useAxiosPrivate from './useAxiosPrivate';
import useShoppingCart from "./useShoppingCart";

import { SHOPPING_CART_URL, DEFAULT_LIMIT } from "../constants";

const useShoppingCartLoader = () => {
    const { setShoppingCart } = useShoppingCart({});
    const axiosPrivate = useAxiosPrivate();

    const shoppingCartLoader = async () => {
        const response = await axiosPrivate.get(SHOPPING_CART_URL, { params: { limit: DEFAULT_LIMIT }});
        const shoppingCartData = response?.data;

        
        if (shoppingCartData?.count > 0) {
            const existingShoppingCart = shoppingCartData?.results[0];
            
            const response = await axiosPrivate.get(SHOPPING_CART_URL + existingShoppingCart.id + '/item/', { params: { limit: DEFAULT_LIMIT }});
            const shoppingCartItemsData = response?.data;
            const shoppingCartItems = shoppingCartItemsData?.results.map(async shoppingCartItem => {
                const response = await axiosPrivate.get(SHOPPING_CART_URL + shoppingCartItem.shopping_cart + '/item/' + shoppingCartItem.id + '/product/', { params: { limit: DEFAULT_LIMIT }})
                return {
                    ...shoppingCartItem,
                    product: response.data.results[0],
                };
            });

            const updatedShoppingCartData = {
                ...existingShoppingCart,
                shopping_cart_items: await Promise.all(shoppingCartItems),
            };
            setShoppingCart(updatedShoppingCartData);
        }
    };

    return shoppingCartLoader;
}

export default useShoppingCartLoader