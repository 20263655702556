import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';
import Loading from "./Loading";

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useCurrentRolesLoader from '../hooks/useCurrentRolesLoader';

import apiDateConverter from '../utils/apiDateConverter';
import { DEFAULT_ROLES, TENANT_URL, ORDER_URL, DEFAULT_LIMIT } from "../constants";
import { isAllowed } from "../utils/authUtils";


const Order = () => {
    const { t, i18n } = useTranslation();
    const { store_slug, order_code } = useParams();
    
    const [order, setOrder] = useState({});
    const [currentStore, setCurrentStore] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const loadCurrentRoles = useCurrentRolesLoader();


    const getOrder = async () => {
        try {
            let currentOrder = {};
            let orderStoreStatus = {};
            let orderItems = [];
            if (store_slug) {
                const responseStores = await axiosPrivate.get(TENANT_URL, { params: { limit: DEFAULT_LIMIT } });
                const storesData = responseStores?.data;
                const store = storesData?.results.find(store => store.slug === store_slug);
                setCurrentStore(store);

                const responseOrders = await axiosPrivate.get(TENANT_URL + store.id + '/order/', { params: { limit: DEFAULT_LIMIT } });
                const ordersData = responseOrders?.data;
                currentOrder = ordersData?.results.find(order => order.order_code === order_code);

                orderStoreStatus = store?.statuses?.order.find(status => status.id === currentOrder?.order_status);

                const responseOrderItems = await axiosPrivate.get(TENANT_URL + store.id + '/order/' + currentOrder?.id + '/item/', { params: { limit: DEFAULT_LIMIT } });
                const orderItemsData = responseOrderItems?.data;
                orderItems = orderItemsData?.results.map(async orderItem => {
                    const response = await axiosPrivate.get(TENANT_URL + store.id + '/order/' + currentOrder?.id + '/item/' + orderItem.id + '/product/', { params: { limit: DEFAULT_LIMIT } });
                    const productData = response?.data;
                    return {
                        ...orderItem,
                        product: productData?.results[0],
                    };
                })

            } else {
                const responseOrders = await axiosPrivate.get(ORDER_URL, { params: { limit: DEFAULT_LIMIT } });
                const ordersData = responseOrders?.data;
                currentOrder = ordersData?.results.find(order => order.order_code === order_code);

                const responseOrderStore = await axiosPrivate.get(TENANT_URL + currentOrder?.tenant + '/');
                const orderStoreData = responseOrderStore?.data;
                orderStoreStatus = orderStoreData?.statuses.order.find(status => status.id === currentOrder?.order_status);

                const responseOrderItems = await axiosPrivate.get(ORDER_URL + currentOrder?.id + '/item/', { params: { limit: DEFAULT_LIMIT } });
                const orderItemsData = responseOrderItems?.data;
                orderItems = orderItemsData?.results.map(async orderItem => {
                    const response = await axiosPrivate.get(ORDER_URL + orderItem.order + '/item/' + orderItem.id + '/product/', { params: { limit: DEFAULT_LIMIT } });
                    const productData = response?.data;
                    return {
                        ...orderItem,
                        product: productData?.results[0],
                    };
                })

            }
            currentOrder.order_items = await Promise.all(orderItems)

            let orderStatusText = order.order_status;
            if (orderStoreStatus) {
                orderStatusText = orderStoreStatus['name_' + i18n.resolvedLanguage] || orderStoreStatus.name_en;
            }
            currentOrder.order_store_status = orderStoreStatus;
            currentOrder.order_status_text = orderStatusText;
            setOrder(currentOrder);
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getOrder();
    }, [order_code])

    const OrderItemsList = ({ orderItems }) => {
        if (orderItems) {
            return orderItems.map(item => {
                if (item.is_active === true) {
                    return <OrderItem key={item.id} orderItem={item} />
                } else {
                    return <></>
                }
            })
        } else {
            return (
                <></>
            )
        }
    }

    const OrderItem = ({ orderItem }) => {
        return (
            <div className="row">
                <div className="col">
                    <p>{orderItem.quantity} x {orderItem.product.name}</p>
                </div>
                <div className="col">
                    <p className="fw-bold text-end">
                        <span style={{ color: "rgb(32, 33, 36)" }}>{orderItem.subtotal} ILS</span>
                    </p>
                </div>
            </div>
        )
    }


    const RemoveProductItemFromOrder = ({ orderItem }) => {
        const removeProductFromOrder = async () => {
            try {
                await axiosPrivate.patch(TENANT_URL + currentStore.id + '/order/' + order.id + '/item/' + orderItem.id + '/', {
                    quantity: orderItem.quantity - 1
                })
                await getOrder();
            } catch (err) {
                console.error(err);
            }
        }

        return (
            <button className="btn btn-outline-primary btn-sm px-1" onClick={removeProductFromOrder}>
                <span>-</span>
            </button>
        )
    }

    const AddSameProductItemToOrder = ({ orderItem }) => {
        const addSameProductItemToOrder = async () => {
            try {
                await axiosPrivate.patch(TENANT_URL + currentStore.id + '/order/' + order.id + '/item/' + orderItem.id + '/', {
                    quantity: orderItem.quantity + 1
                })
                await getOrder();
            } catch (err) {
                console.error(err);
            }
        }

        return (
            <button className="btn btn-outline-primary btn-sm px-1" onClick={addSameProductItemToOrder}>
                <span>+</span>
            </button>
        )
    }

    const RemoveAllProductItemsFromOrder = ({ orderItem }) => {
        const removeAllProductItemsFromOrder = async () => {
            try {
                await axiosPrivate.patch(TENANT_URL + currentStore.id + '/order/' + order.id + '/item/' + orderItem.id + '/', {
                    quantity: 0
                })
                await getOrder();
            } catch (err) {
                if (err.response.status === 404) {
                    setOrder({});
                    navigate('/');
                } else {
                    console.error(err);
                }
            }
        }
        return (
            <a className="text-bg-danger bs-icon bs-icon-sm bs-icon-circle" href="#" onClick={removeAllProductItemsFromOrder}>
                <svg className="bi bi-trash3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"></path>
                </svg>
            </a>
        )
    }

    const OrderItemsTableRow = ({ orderItem }) => {
        return (
            <tr>
                <td className="align-middle">{orderItem.product.name}</td>
                <td className="align-middle">{orderItem.product.price} ILS</td>
                <td className="align-middle">
                    <div className="d-flex flex-row">
                        <div className="input-group align-self-center" style={{ width: "4em" }}>
                            <RemoveProductItemFromOrder orderItem={orderItem} />
                            <input readOnly className="form-control form-control-sm p-1 text-center" type="text" value={orderItem.quantity} />
                            <AddSameProductItemToOrder orderItem={orderItem} />
                        </div>
                        <div className="align-self-center mx-1">
                            <RemoveAllProductItemsFromOrder orderItem={orderItem} />
                        </div>
                    </div>
                </td>
                <td className="text-end align-middle">{orderItem.subtotal} ILS</td>
            </tr>
        )
    }

    const OrderItemsTable = ({ orderItems }) => {
        if (orderItems) {
            return (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{t('Product')}</th>
                                <th>{t('Price')}</th>
                                <th>{t('Quantity')}</th>
                                <th className="text-end">{t('Total')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderItems.map((item) => <OrderItemsTableRow key={item.id} orderItem={item} />)}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }


    const OrderStatus = ({ currentRoles }) => {
        const changeStatusHandler = async (e) => {
            e.preventDefault();
            try {
                await axiosPrivate.patch(TENANT_URL + currentStore.id + '/order/' + order.id + '/', {
                    order_status: e.target.value
                })
                // setOrder({ ...order, order_status: e.target.value })
                await getOrder();
            } catch (err) {
                console.error(err);
            }
        }

        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners]) && currentStore && !['cancelled', 'delivered'].includes(order.order_store_status.slug)) {
            return (
                <select className="form-select" defaultValue={order.order_status} onChange={(e) => changeStatusHandler(e)}>
                    {
                        order.order_status_options.map(status_id => {
                            const currentStoreStatus = currentStore?.statuses?.order.find(storeStatus => storeStatus.id === status_id);
                            return <option value={currentStoreStatus.id} key={currentStoreStatus.slug} >{currentStoreStatus.name}</option>
                        })
                    }
                </select>
            )
        } else {
            return (
                <p>{order.order_status_text}</p>
            )
        }
    }

    const OrderItemsListOrTable = ({ orderItems, currentRoles }) => {
        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners]) && orderItems && ['new'].includes(order.order_store_status.slug)) {
            return (
                <OrderItemsTable orderItems={orderItems} />
            )
        } else {
            return (
                <>
                    <OrderItemsList orderItems={orderItems} />
                    <div className="row">
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                </>
            )
        }
    }

    const Result = ({ order, isLoading }) => {
        if (isLoading) {
            return (
                <Loading />
            )
        } else if (order.order_code === order_code) {
            return (
                <>
                    <ContentHeader text="Order" subtext="Order details" />
                    <div className="row">
                        <div className="col pb-4">
                            <div style={{ border: "1px solid var(--bs-gray-400)", borderRadius: "20px" }}>
                                <div className="p-4">
                                    <h4 className="fw-bold mb-4">{t('Order')} #{order.order_code}</h4>
                                    <p className="fw-bold mb-0">{t('Created')}</p>
                                    <p className="mb-0">{apiDateConverter(order.created)}</p>
                                    <br />
                                    <p className="fw-bold mb-0">{t('Status')}</p>
                                    <OrderStatus currentRoles={loadCurrentRoles()} />
                                </div>
                            </div>
                        </div>
                        <div className="col pb-4">
                            <div style={{ border: "1px solid var(--bs-gray-400)", borderRadius: "20px" }}>
                                <div className="p-4">
                                    <h4 className="fw-bold mb-4">{t('Customer Details')}</h4>
                                    {/* <p className="fw-bold mb-0">{t('Name')}</p>
                                        <p>Jeanie Glover</p>  */}
                                    <p className="fw-bold mb-0">{t('Email')}</p>
                                    <p>{order.user?.email}</p>
                                    <p className="fw-bold mb-0">{t('Phone')}</p>
                                    <p className="mb-0">{order.user?.phone_number}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col pb-4">
                            <div style={{ border: "1px solid var(--bs-gray-400)", borderRadius: "20px" }}>
                                <div className="p-4">
                                    <div className="row">
                                        <div className="col">
                                            <h4 className="fw-bold mb-4">{t('Order Summary')}</h4>
                                        </div>
                                    </div>
                                    <OrderItemsListOrTable orderItems={order.order_items} currentRoles={loadCurrentRoles()} />
                                    <div className="row">
                                        <div className="col">
                                            <p>{t('Subtotal')}</p>
                                            <p>{t('Shipping')}</p>
                                            <p>{t('Tax')} (17%)</p>
                                        </div>
                                        <div className="col">
                                            <p className="text-end"><span
                                                style={{ color: "rgb(32, 33, 36)" }}>{order.subtotal} ILS</span></p>
                                            <p className="text-end"><span
                                                style={{ color: "rgb(32, 33, 36)" }}>{order.shipping_price} ILS</span></p>
                                            <p className="text-end"><span style={{ color: "rgb(32, 33, 36)" }}>{order.tax} ILS</span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>{t('Total')}</p>
                                        </div>
                                        <div className="col">
                                            <p className="fw-bold text-end"><span style={{ color: "rgb(32, 33, 36)" }}>{order.total} ILS</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col pb-4">
                            <div style={{ border: "1px solid var(--bs-gray-400)", borderRadius: "20px" }}>
                                <div className="p-4">
                                    <h4 className="fw-bold mb-4">{t('Shipping')}</h4>
                                    <p className="fw-bold mb-0">{t('Shipping Method')}</p>
                                    <p>Standard Delivery ({order.shipping_price} ILS)</p>
                                    <p className="fw-bold mb-0">{t('Shipping Address')}</p>
                                    <p>Address: {order.user?.address}</p>
                                    <p className="fw-bold mb-0">{t('Note')}</p>
                                    <p className="mb-0">Note text.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <ContentHeader text="Order" subtext="Order details" />
                    <p className="text-center"> Order not found. </p>
                </>
            )
        }
    }

    return <Result order={order} isLoading={isLoading} />
}

export default Order