import useSearchArgs from "../hooks/useSearchArgs";

const Paginator = ({ page_obj, loader }) => {
    const { searchArgs } = useSearchArgs();

    const PagesButton = ({ page }) => {
        return (
            <li className={page_obj.current_page === page ? "page-item active" : "page-item"} >
                <button className="page-link" onClick={() => loader({ ...searchArgs, page })}>{ page }</button>
            </li>
        )
    }

    const PagesButtonsList = ({ total_pages }) => {
        const pagesArray = [...Array(total_pages).keys()]
        return (
            pagesArray.map((page) => {
                return (
                    <PagesButton key={page} page={page + 1} />
                )
            })
        )
    }

    const PreviousPageButton = ({ previous_page_link}) => {
        return (
            <li className={previous_page_link ? "page-item" : "page-item disabled"} >
                <button className="page-link" aria-label="Previous" onClick={() => loader({ ...searchArgs, page: page_obj.current_page - 1 })}>
                    <span aria-hidden="true">«</span>
                </button>
            </li>
        )
    }

    const NextPageButton = ({ next_page_link }) => {
        return (
            <li className={next_page_link ? "page-item" : "page-item disabled"}>
                <button className="page-link" aria-label="Next" onClick={() => loader({ ...searchArgs, page: page_obj.current_page + 1 })}>
                    <span aria-hidden="true">»</span>
                </button>
            </li>
        )
    }

    if (page_obj.total_pages > 1) {
        return (
            <div className="row">
                <div className="col mx-auto col-auto">
                    <nav>
                        <ul className="pagination">
                            <PreviousPageButton previous_page_link={page_obj.links.previous} />
                            <PagesButtonsList total_pages={page_obj.total_pages} />
                            <NextPageButton next_page_link={page_obj.links.next} />
                        </ul>
                    </nav>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default Paginator