import { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import useAuth from "../hooks/useAuth";
import useLocalStorage from "../hooks/useLocalStorage";
import useShoppingCart from "../hooks/useShoppingCart";
import useShoppingCartLoader from "../hooks/useShoppingCartLoader";
import useCurrentRolesLoader from '../hooks/useCurrentRolesLoader'; 


import { isAllowed } from "../utils/authUtils";
import { DEFAULT_ROLES } from "../constants";


const NavbarButtons = () => {
    const { auth } = useAuth();
    const { t, i18n } = useTranslation();
    const { store_slug } = useParams();
    const { shoppingCart } = useShoppingCart({});
    const [, setLocalLang] = useLocalStorage('lang', 'en');

    const loadShoppingCart = useShoppingCartLoader();
    const loadCurrentRoles = useCurrentRolesLoader();

    const getShoppingCart = async () => {
        if (auth?.accessToken && auth?.user) {
            try {
                await loadShoppingCart();
            } catch (err) {
                console.error(err);
            }
        }
    }

    useEffect(() => {    
        getShoppingCart();
    }, [auth])


    const StoreOrdersButton = ({ storeSlug }) => {
        return (
            <li className="nav-item mx-1 my-1">
                <Link to={`/${storeSlug}/orders`} className="bs-icon bs-icon-md bs-icon-circle bs-icon-primary shadow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M7 18H17V16H7V18Z" fill="currentColor"></path>
                        <path d="M17 14H7V12H17V14Z" fill="currentColor"></path>
                        <path d="M7 10H11V8H7V10Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z" fill="currentColor"></path>
                    </svg>
                </Link>
            </li>
        )
    }

    const UserButton = () => {
        return (
            <li className="nav-item dropdown mx-1 my-1">
                <Link className="bs-icon bs-icon-md bs-icon-circle bs-icon-primary shadow" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-person">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                    </svg>
                </Link>
                <ul className="dropdown-menu position-absolute">
                    <li className="dropdown-item">
                        <Link to="/user/profile">{t('Profile')}</Link>
                    </li>
                    <li className="dropdown-item">
                        <Link to="/user/orders">{t('Orders')}</Link>
                    </li>
                </ul>
            </li>
        )
    }

    const CartButton = ({ shoppingCartItems }) => {
        // getShoppingCart();
        // if (auth?.accessToken && auth?.user) {
        let cartCounter = 0;
        if (shoppingCartItems?.length > 0) {
            cartCounter = shoppingCartItems.reduce((total, item) => total + item.quantity, 0);
        }
        return (
            <li className="nav-item mx-1 my-1">
                <Link to="/user/shopping-cart" className="bs-icon bs-icon-md bs-icon-circle bs-icon-primary shadow">
                    <div className="position-relative">
                        {cartCounter > 0
                            ? <span className="fs-6 position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger mx-2">{cartCounter}</span>
                            : <></>
                        }
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-cart2">
                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                        </svg>
                    </div>
                </Link>
            </li>
        )
        // } else {
        //     return <></>;
        // }
    }

    const LoginButton = () => {
        return (
            <li className="nav-item mx-1 my-1">
                <Link to="/login" className="bs-icon bs-icon-md bs-icon-circle bs-icon-primary shadow" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M15.4857 20H19.4857C20.5903 20 21.4857 19.1046 21.4857 18V6C21.4857 4.89543 20.5903 4 19.4857 4H15.4857V6H19.4857V18H15.4857V20Z" fill="currentColor"></path>
                        <path d="M10.1582 17.385L8.73801 15.9768L12.6572 12.0242L3.51428 12.0242C2.96199 12.0242 2.51428 11.5765 2.51428 11.0242C2.51429 10.4719 2.962 10.0242 3.51429 10.0242L12.6765 10.0242L8.69599 6.0774L10.1042 4.6572L16.4951 10.9941L10.1582 17.385Z" fill="currentColor"></path>
                    </svg>
                </Link>
            </li>
        )
    }

    const LogoutButton = () => {
        return (
            <li className="nav-item mx-1 my-1">
                <Link to="/logout" className="bs-icon bs-icon-md bs-icon-circle bs-icon-primary shadow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                        <path d="M8.51428 20H4.51428C3.40971 20 2.51428 19.1046 2.51428 18V6C2.51428 4.89543 3.40971 4 4.51428 4H8.51428V6H4.51428V18H8.51428V20Z" fill="currentColor"></path>
                        <path d="M13.8418 17.385L15.262 15.9768L11.3428 12.0242L20.4857 12.0242C21.038 12.0242 21.4857 11.5765 21.4857 11.0242C21.4857 10.4719 21.038 10.0242 20.4857 10.0242L11.3236 10.0242L15.304 6.0774L13.8958 4.6572L7.5049 10.9941L13.8418 17.385Z" fill="currentColor"></path>
                    </svg>
                </Link>
            </li>
        )
    }

    const LangugeDropdown = () => {
        const lngs = {
            en: { nativeName: 'English' },
            he: { nativeName: 'עברית' },
            ru: { nativeName: 'Русский' }
        };

        const saveLanguage = (lng) => {
            i18n.changeLanguage(lng);
            setLocalLang(lng);
        }

        return (
            <li className="nav-item dropdown mx-1 my-1">
                <Link className="bs-icon bs-icon-md bs-icon-circle bs-icon-primary shadow" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-globe">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"></path>
                    </svg>
                </Link>
                <ul className="dropdown-menu position-absolute" id="language-list">
                    {Object.keys(lngs).map((lng) => (
                        <li key={lng} className="dropdown-item"> <button className="dropdown-item" onClick={() => saveLanguage(lng)}>{lngs[lng].nativeName}</button> </li>
                    ))}
                </ul>
            </li>
        )
    }


    const Result = ({ shoppingCartItems, storeSlug, currentRoles }) => {
        if (auth?.accessToken && auth?.user) {
            if (isAllowed(currentRoles, [DEFAULT_ROLES.owners]) && storeSlug) {
                return (
                    <>
                        <LogoutButton />
                        <StoreOrdersButton storeSlug={storeSlug} />
                        <LangugeDropdown />
                    </>
                )
            } else {
                return (
                    <>
                        <LogoutButton />
                        <UserButton />
                        <CartButton shoppingCartItems={shoppingCartItems} />
                        <LangugeDropdown />
                    </>
                )
            }
        } else {
            return (
                <>
                    <LoginButton />
                    <LangugeDropdown />
                </>
            )
        }
    }

    return (
        <div className="col-sm-3 mx-auto py-2">
            <ul className="navbar-nav flex-row flex-wrap justify-content-center">
                <Result shoppingCartItems={shoppingCart?.shopping_cart_items} storeSlug={store_slug} currentRoles={loadCurrentRoles()} />
            </ul>
        </div>
    )
}

export default NavbarButtons