import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'

import ContentHeader from './ContentHeader';
import Loading from "./Loading";

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import apiDateConverter from '../utils/apiDateConverter';
import { TENANT_URL, ORDER_URL, RATING_STYLES, DEFAULT_LIMIT } from "../constants";


const Orders = () => {
    const { t, i18n } = useTranslation();
    const { store_slug } = useParams();
    
    const [orders, setOrders] = useState([]);
    const [ordersStores, setOrdersStores] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();


    const getOrders = async () => {
        try {
            const response = await axiosPrivate.get(TENANT_URL, { params: { limit: DEFAULT_LIMIT } });
            const storesData = response?.data;

            if (storesData) {
                if (store_slug) {
                    const response = await axiosPrivate.get(TENANT_URL + storesData.results.find(store => store.slug === store_slug).id + '/order/', { params: { limit: DEFAULT_LIMIT } });
                    const ordersData = response?.data;
                    setOrders(ordersData);
                    if (ordersData) {
                        const currentStore = storesData.results.find(store => store.slug === store_slug)
                        setOrdersStores([currentStore]);
                    }
                } else {
                    const response = await axiosPrivate.get(ORDER_URL, { params: { limit: DEFAULT_LIMIT } });
                    const ordersData = response?.data;
                    setOrders(ordersData);
                    if (ordersData) {
                        const filteredStores = ordersData.results.map(order => storesData.results.find(store => store.id === order.tenant));
                        const uniqueStores = [...new Map(filteredStores.map(item => [item['id'], item])).values()]
                        setOrdersStores(uniqueStores);
                    }
                }
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getOrders();
    }, []);


    const Order = ({ order }) => {
        if (order) {
            const orderTenant = ordersStores.find(tenant => tenant.id === order.tenant);
            const orderStatus = orderTenant?.statuses?.order.find(status => status?.id === order?.order_status);
            let orderStatusText = order.order_status;
            if (orderStatus) {
                orderStatusText = orderStatus['name_' + i18n.resolvedLanguage] || orderStatus.name_en;
            }

            const getNavigatePath = () => {
                if (store_slug) {
                    return "/" + store_slug + "/order/" + order.order_code;
                } else {
                    return "/user/order/" + order.order_code;
                }
            }


            const setRating = async (value) => {
                try {
                    await axiosPrivate.put(ORDER_URL + order.id + '/rating/', {
                        "rating": value
                    });
                    await getOrders();
                } catch (err) {
                    console.error(err);
                }
            }

            return (
                <tr style={{ cursor: "pointer" }} onClick={() => navigate(getNavigatePath())}>
                    <td className="align-middle">{order.order_code}</td>
                    <td className="align-middle">{orderTenant?.name ? orderTenant.name : order.tenant}</td>
                    <td className="align-middle">{apiDateConverter(order.created)}</td>
                    <td className="align-middle">{orderStatusText}</td>
                    <td className="align-middle">{order.quantity}</td>
                    <td className="align-middle">{order.total}</td>
                    <td className="align-middle">
                        {
                            !['new'].includes(orderStatus.slug)
                                ? <Rating
                                    style={{ maxHeight: 32, maxWidth: 120 }}
                                    value={order.rating}
                                    itemStyles={RATING_STYLES}
                                    onChange={setRating}
                                    readOnly={store_slug}
                                />
                                : <></>
                        }
                    </td>
                </tr>
            )
        } else {
            return <tr></tr>
        }
    }

    const OrderList = ({ orders }) => {
        if (orders?.length > 0) {
            const sorted_orders = orders.sort(function (a, b) {
                return new Date(b.created) - new Date(a.created);
            });

            if (sorted_orders) {
                return (
                    <tbody>
                        {
                            sorted_orders.map(
                                (order) => (<Order key={order?.order_code} order={order} />)
                            )
                        }
                    </tbody>
                )
            }
        }
        return (
            <tbody>
                <tr>
                    <td className="text-center" colSpan="7">{t('No orders found for this order')}</td>
                </tr>
            </tbody>
        )

    }


    const Result = ({ orders, isLoading }) => {
        if (isLoading) {
            return (
                <Loading />
            )
        } else {
            return (
                <>
                    <ContentHeader text={t('Orders')} subtext={t('Your orders')} />
                    <div className="row">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{t('Order')}</th>
                                            <th>{t('Store')}</th>
                                            <th>{t('Date')}</th>
                                            <th>{t('Status')}</th>
                                            <th>{t('Products')}</th>
                                            <th>{t('Total')}</th>
                                            <th>{t('Rating')}</th>
                                        </tr>
                                    </thead>
                                    <OrderList orders={orders} />
                                </table>
                                {/* {% include "partials/paginator.html" %} */}
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    return <Result orders={orders.results} isLoading={isLoading} />
}

export default Orders