import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';


const Unauthorized = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    // const goBack = () => navigate(-1);

    return (
        <>
            <ContentHeader text={t('Unauthorized')} subtext={t('You do not have access to the requested page')} />
            {/* <button className="btn btn-primary" onClick={goBack}>Go Back</button> */}
        </>

    )
}

export default Unauthorized
