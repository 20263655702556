import { useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
// import { Link } from "react-router-dom";

import useAxiosPrivate from "../hooks/useAxiosPrivate"


const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CHANGE_PASSWORD_URL = '/auth/password/change/';

const ChangePassword = () => {
    const { t } = useTranslation();

    // const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    // const emailRef = useRef();
    const errRef = useRef();

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        setValidMatch(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {
        setErrMsg('');
    }, [password, matchPassword])


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v2 = PWD_REGEX.test(password);
        if (!v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            await axiosPrivate.post(CHANGE_PASSWORD_URL,
                JSON.stringify(
                    {
                        new_password1: password,
                        new_password2: matchPassword
                    })
            );
            setSuccess(true);

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-xl-4">
                <div className="card-body text-center d-flex flex-column align-items-center">
                    <h2 className="fw-bold">{t("Password Change")}</h2>
                    <p className="w-lg-50">{t("Update your password")}</p>
                    <br />
                    <form onSubmit={handleSubmit} method="post" data-bs-theme="light">
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            aria-invalid={validPassword ? "false" : "true"}
                            aria-describedby="passwordnote"
                            onFocus={() => setPasswordFocus(true)}
                            onBlur={() => setPasswordFocus(false)}
                            placeholder={t('Password')}
                            className='form-control mb-3'
                        />
                        {
                            passwordFocus && !validPassword
                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                    {t('8 to 24 characters')}<br />
                                    {t('Must include uppercase and lowercase letters, a number and a special character')}<br />
                                    {t('Allowed special characters') + ": "} <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                </p>
                                : null
                        }
                        <input
                            type="password"
                            id="confirm_password"
                            onChange={(e) => setMatchPassword(e.target.value)}
                            value={matchPassword}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                            placeholder={t('Repeat your password')}
                            className='form-control mb-3'
                        />
                        {
                            matchFocus && !validMatch
                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                    {t('Must match the first password input field')}
                                </p>
                                : null
                        }
                        <button className="btn btn-primary shadow d-block w-100 mb-3" disabled={!validPassword || !validMatch ? true : false}>{t('Update')}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword