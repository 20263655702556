import useAxiosPrivate from './useAxiosPrivate';
import useStores from "./useStores";
import { SEARCH_URL } from "../constants";


const useStoresLoader = () => {
    const { setStores } = useStores();
    const axiosPrivate = useAxiosPrivate();

    const storesLoader = async (params = {}) => {
        try {
            const response = await axiosPrivate.get(SEARCH_URL, { params });
            setStores(response?.data);
        } catch (err) {
            console.error(err);
        }
    }

    return storesLoader; 
}

export default useStoresLoader;