import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";

import useAuth from '../hooks/useAuth';
import useStoresLoader from "../hooks/useStoresLoader";

import Loading from "./Loading";


const LoadStores = () => {
    const { auth } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const loadStores = useStoresLoader();

    useEffect(() => {
        // Get stores from the server
        const getStores = async () => {
            try {
                await loadStores();
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }

        getStores();
    }, [auth])

    return (
        <>
            {
                isLoading
                    ? <Loading />
                    : <Outlet />
            }
        </>
    )
}

export default LoadStores