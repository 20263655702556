import useAxiosPrivate from '../hooks/useAxiosPrivate';

import useAuth from "./useAuth";

const LOGOUT_URL = '/auth/logout/';

const useLogout = () => {
    const axiosPrivate = useAxiosPrivate();
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        try {
            await axiosPrivate.post(LOGOUT_URL)
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout