import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tags from '@yaireo/tagify/dist/react.tagify.jsx' // React-wrapper file
import '@yaireo/tagify/dist/tagify.css' // Tagify CSS
import './NavbarSearch.css'

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useStores from "../hooks/useStores";
import useSearchArgs from "../hooks/useSearchArgs";
 
import { SEARCH_URL, TAGS_URL } from "../constants";


const NavbarSearch = () => {
    const { t } = useTranslation();
    const { setStores } = useStores();
    const { searchArgs, setSearchArgs } = useSearchArgs();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const SearchTags = () => {
        const onInput = useCallback((e) => {
            var tagify = e.detail.tagify
            const value = e.detail.value

            tagify.whitelist = null // reset the whitelist

            // show loading animation and hide the suggestions dropdown
            tagify.loading(true).dropdown.hide()

            axiosPrivate.get(TAGS_URL, { params: { limit: 10, name: value } }).then(({ data }) => {
                tagify.whitelist = data.results.map((tag) => tag.name); // update whitelist Array in-place
                tagify.loading(false).dropdown.show(value) // render the suggestions dropdown
            })
        }, [])

        const onChange = useCallback((e) => {
            setSearchArgs({ 
                ...searchArgs, 
                tags: e.detail.tagify.getCleanValue().map((tag) => tag.value).join(",")
            })
        }, [])

        return (
            <Tags
                placeholder={t("Search")}
                settings={{
                    // maxTags: 4,
                    maxItems: 15,
                    dropdown: {
                        enabled: 1
                    }
                }}
                defaultValue={searchArgs.tags ? searchArgs.tags : []}
                onInput={onInput}
                onChange={onChange}
            // className="customLook"
            />
        )
    }

    const FilterByCity = ({cities}) => {
        const CityOptions = () => {
            if (cities) {
                return cities.map((city) => <option value={city.name} key={city.name}>{city.name}</option>)
            } else {
                return <></>
            }
        }

        return (
            <>
                <strong>{t("Filter by city")}:</strong>
                <select
                    className="form-select"
                    name="city"
                    value={searchArgs.city ? searchArgs.city : ""}
                    onChange={(e) => {
                        if (e.target.value !== "") {
                            setSearchArgs({...searchArgs, city: e.target.value})
                        } else {
                            let newSearchArgs = {...searchArgs}
                            delete newSearchArgs.city
                            setSearchArgs({...newSearchArgs})
                        }
                    }}
                >
                    <option value="">{t("All")}</option>
                    <CityOptions />
                </select>
            </>
        )
    }

    const OrderBy = () => {
        return (
            <>
                <strong>{t("Order by")}:</strong>
                <select
                    className="form-select"
                    name="order_by"
                    value={searchArgs.order_by ? searchArgs.order_by : "-rating"}
                    onChange={(e) => setSearchArgs({...searchArgs, order_by: e.target.value})}
                >
                    <option value="name">{t("Name")}: {t("(A-Z)")}</option>
                    <option value="-name">{t("Name")}: {t("(Z-A)")}</option>
                    <option value="price">{t("Price")}: {t("Low to High")}</option>
                    <option value="-price">{t("Price")}: {t("High to Low")}</option>
                    <option value="rating">{t("Rating")}: {t("Low to High")}</option>
                    <option value="-rating">{t("Rating")}: {t("High to Low")}</option>
                </select>
            </>
        )
    }

    const RatingGreater = () => {
        return (
            <>
                <strong>{t("Rating greater than")}:</strong>
                <select 
                    className="form-select"
                    name="rating_greater"
                    value={searchArgs.rating_greater ? searchArgs.rating_greater : ""}
                    onChange={(e) => {
                        if (e.target.value !== "") {
                            setSearchArgs({...searchArgs, rating_greater: e.target.value})
                        } else {
                            let newSearchArgs = {...searchArgs}
                            delete newSearchArgs.rating_greater
                            setSearchArgs({...newSearchArgs})
                        }
                    }}
                >
                    <option value="">{t("Any")}</option>
                    <option value="1">{"\u2B50"}</option>
                    <option value="2">{"\u2B50 \u2B50"}</option>
                    <option value="3">{"\u2B50 \u2B50 \u2B50"}</option>
                    <option value="4">{"\u2B50 \u2B50 \u2B50 \u2B50"}</option>
                    <option value="5">{"\u2B50 \u2B50 \u2B50 \u2B50 \u2B50"}</option>
                </select>
            </>
        )
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await axiosPrivate.get(SEARCH_URL, { params: searchArgs })
        setStores(response?.data)
        navigate("/")
    }

    return (
        <div className="col-sm-6 mx-auto py-2 px-4">
            <form role="search" name="search" onSubmit={handleSubmit}  >
                <div className="input-group d-flex flex-nowrap" placeholder="Search" aria-label="Search" name="search">
                    <button className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul className="dropdown-menu">
                        {/* {% if not tenant.slug %} */}
                        <li style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }}>
                            <FilterByCity cities={[{name: "Tel-Aviv"},{name: "Jerusalem"}]} />
                        </li>
                        <li style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }}>
                            <hr className="my-1" />
                        </li>
                        {/* {% endif %} */}
                        <li style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }}>
                            <OrderBy />
                        </li>
                        {/* {% if not tenant.slug %} */}
                        <li style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }}>
                            <hr className="my-1" />
                        </li>
                        <li style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }}>
                            <RatingGreater />
                        </li>
                        {/* {% endif %} */}
                    </ul>
                    <SearchTags />
                    <button className="btn btn-outline-primary px-3" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                            viewBox="0 0 16 16" className="bi bi-search fs-5 mb-1">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default NavbarSearch