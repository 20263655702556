import { Routes, Route } from 'react-router-dom';

import Register from './components/Register';
import Login from './components/Login';
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireRoles from './components/RequireRoles';
import PersistLogin from './components/PersistLogin';
import Logout from './components/Logout';
import UserProfile from './components/UserProfile';
import ChangePassword from './components/ChangePassword';
import Stores from './components/Stores';
import Store from './components/Store';
import LoadStores from './components/LoadStores';
import ShoppingCart from './components/ShoppingCart';
import Orders from './components/Orders';
import Order from './components/Order';
import ProductDetails from './components/ProductDetails';

import ReactGA from 'react-ga4';


import { DEFAULT_ROLES } from "./constants";


const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

  return (
    <Routes>
      <Route element={<LoadStores />}>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="logout" element={<Logout />} />

          {/* we want to protect these routes */}
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Stores />} />
            <Route path=":store_slug" element={<Store />} />
            <Route element={<RequireRoles allowedRoles={[DEFAULT_ROLES.owners]} />}>
              <Route path=":store_slug/product/edit/:product_code" element={<ProductDetails />} />
              <Route path=":store_slug/orders" element={<Orders />} />
              <Route path=":store_slug/order/:order_code" element={<Order />} />
            </Route>
            
            <Route path="user/profile" element={<UserProfile />} />
            <Route path="user/change-password" element={<ChangePassword />} />
            <Route path="user/shopping-cart" element={<ShoppingCart />} />
            <Route path="user/orders" element={<Orders />} />
            <Route path="user/order/:order_code" element={<Order />} />
          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Route>
    </Routes>

  );
}

export default App