import { useRef, useState, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
// import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import axios from '../api/axios';

import ErrorToast from './ErrorToast';


// const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_NUMBER_REGEX = /^([+\d].*)?\d$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/auth/registration/';


const Register = () => {
    const { t } = useTranslation();

    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onVerify = useCallback((token) => {
        setToken(token)
    }, []);

    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

    const [address, setAddress] = useState('');

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPhoneNumber(PHONE_NUMBER_REGEX.test(phoneNumber));
    }, [phoneNumber])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        setValidMatch(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {
        setErrMsg('');
    }, [email, password, matchPassword])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setRefreshReCaptcha(r => !r);

        // if button enabled with JS hack
        const v1 = EMAIL_REGEX.test(email);
        const v2 = PWD_REGEX.test(password);
        const v3 = PHONE_NUMBER_REGEX.test(phoneNumber);
        if (!v1 || !v2 || !v3) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            await axios.post(REGISTER_URL,
                JSON.stringify(
                    {
                        email,
                        phone_number: phoneNumber,
                        address,
                        password1: password,
                        password2: matchPassword
                        // token,
                    }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setSuccess(true);

            //clear state and controlled inputs
            setEmail('');
            setPhoneNumber('');
            setAddress('');
            setPassword('');
            setMatchPassword('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            // errRef.current.focus();
        }
    }

    return (
        <>
            {success ? (
                <section>
                    <h1>{t('Success') + "!"}</h1>
                    <p>
                        <Link to="/login">{t('Sign In')}</Link>
                    </p>
                </section>
            ) : (
                <section>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card">
                                <div className="card-body text-center d-flex flex-column align-items-center">
                                    {/* <p className={errMsg ? "errmsg alert alert-danger text-center" : "offscreen"} ref={errRef} aria-live="assertive">{errMsg}</p> */}
                                    <ErrorToast errMsg={errMsg} />
                                    <h2 className="fw-bold">{t('Sign Up')}</h2>
                                    <p className="w-lg-50">{t('Create an account')}</p>
                                    <br />
                                    <form id="signupform" onSubmit={handleSubmit} method="post" data-bs-theme="light">
                                        <GoogleReCaptcha
                                            onVerify={onVerify}
                                            refreshReCaptcha={refreshReCaptcha}
                                        />
                                        <input
                                            type="text"
                                            id="email"
                                            ref={emailRef}
                                            autoComplete="off"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            required
                                            aria-invalid={validEmail ? "false" : "true"}
                                            aria-describedby="uidnote"
                                            onFocus={() => setEmailFocus(true)}
                                            onBlur={() => setEmailFocus(false)}
                                            placeholder={t('Email')}
                                            className='form-control mb-3'
                                        />
                                        {
                                            emailFocus && email && !validEmail
                                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                                    {t('Invalid email address')}<br />
                                                    {/* {t('Letters, numbers, underscores, hyphens allowed')} */}
                                                </p>
                                                : null
                                        }

                                        <input
                                            type="text"
                                            id="phone_number"
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            value={phoneNumber}
                                            required
                                            aria-invalid={validPhoneNumber ? "false" : "true"}
                                            aria-describedby="phonenote"
                                            onFocus={() => setPhoneNumberFocus(true)}
                                            onBlur={() => setPhoneNumberFocus(false)}
                                            autoComplete="off"
                                            placeholder={t('Phone Number')}
                                            className='form-control mb-3'
                                        />
                                        {
                                            phoneNumberFocus && phoneNumber && !validPhoneNumber
                                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                                    {t("Invalid phone number")}<br />
                                                </p>
                                                : null
                                        }

                                        <input
                                            type="text"
                                            id="address"
                                            onChange={(e) => setAddress(e.target.value)}
                                            value={address}
                                            required
                                            autoComplete="off"
                                            placeholder={t('Address')}
                                            className='form-control mb-3'
                                        />

                                        <input
                                            type="password"
                                            id="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            required
                                            aria-invalid={validPassword ? "false" : "true"}
                                            aria-describedby="passwordnote"
                                            onFocus={() => setPasswordFocus(true)}
                                            onBlur={() => setPasswordFocus(false)}
                                            placeholder={t('Password')}
                                            className='form-control mb-3'
                                        />
                                        {
                                            passwordFocus && !validPassword
                                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                                    {t('8 to 24 characters')}<br />
                                                    {t('Must include uppercase and lowercase letters, a number and a special character')}<br />
                                                    {t('Allowed special characters') + ": "} <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                                </p>
                                                : null
                                        }
                                        <input
                                            type="password"
                                            id="confirm_password"
                                            onChange={(e) => setMatchPassword(e.target.value)}
                                            value={matchPassword}
                                            required
                                            aria-invalid={validMatch ? "false" : "true"}
                                            aria-describedby="confirmnote"
                                            onFocus={() => setMatchFocus(true)}
                                            onBlur={() => setMatchFocus(false)}
                                            placeholder={t('Repeat your password')}
                                            className='form-control mb-3'
                                        />
                                        {
                                            matchFocus && !validMatch
                                                ? <p id="confirmnote" className="alert alert-danger text-center">
                                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                                    {t('Must match the first password input field')}
                                                </p>
                                                : null
                                        }

                                        <button className="btn btn-primary shadow d-block w-100 mb-3" disabled={!validEmail || !validPhoneNumber || !address || !validPassword || !validMatch ? true : false}>{t('Sign Up')}</button>

                                    </form>

                                    <p className="text-muted">
                                        <span>{t('Already have an account') + "? "}</span>
                                        <Link to="/login">{t('Login')}</Link>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            )}
        </>
    )
}

export default Register
